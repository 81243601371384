import React from 'react';
import { useEffect } from 'react'
import { useListView } from '../../core/ListViewProvider'
import { UserEditModalHeader } from '../../form-edit-modal/UserEditModalHeader'
import { KTSVG } from '../../../../../../../_metronic/helpers'

interface ModalProps {
  formName?: string
  headerName?: string
  viewPage?: string
}

const ViewImageModal = ({ close, images, name }: any) => {
  const configName = {
    PRE_STAGE: 'Before',
    IN_PROGRESS_STAGE: 'In Progress',
    POST_STAGE: 'After',
    LOADING: 'Loading',
    SLIP: 'Slip',
  }
  const filterImage = (images?.length && images?.filter((x) => x.imageType === name)) || []
  console.log(
    { name, filterImage, images },

    filterImage?.length ? filterImage : images
  )
  const tableHeaderName = [
    { label: 'Clean Up ID', value: 'orderId' },
    { label: 'Country', value: (data) => data?.customerInfo?.address?.country },
    {
      label: 'Geo Location',
      value: (data) => data?.dispatchInfo?.latitute + ' , ' + data?.dispatchInfo?.longitute,
    },
    { label: 'Captain', value: (data) => data?.customerInfo?.name },
    { label: 'Environment', value: (data) => data?.dispatchInfo?.street },
    { label: 'Team', value: (data) => data?.teamInfo?.teamName },
  ]

  const tableHeaderSecond = [
    {
      label: 'KPI',
      value: '-',
    },
    {
      label: 'Unit',
      value: '-',
    },
    {
      label: 'Unsorted',
      value: (data) => data.teamInfo?.teamName,
    },
    {
      label: 'Plastic',
      value: (data) => data.teamInfo?.teamName,
    },
    {
      label: 'Non Plastic',
      value: (data) => data.teamInfo?.teamName,
    },
  ]

  return (
    <>
      {
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            {/* begin::Modal dialog */}
            {/* <div className='modal-dialog modal-dialog-centered mw-650px'> */}
            <div
              className={`modal-dialog 
           mw-850px modal-dialog-centered`}
            >
              {/* begin::Modal content */}
              <div className='modal-content'>
                {/* <UserEditModalHeader headerName={'Confirmation'} /> */}
                <div className='modal-header'>
                  <h5 className='modal-title'>{configName[name]}</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={close}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                {/* begin::Modal body */}
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      gap: 8,
                    }}
                  >
                    {name === 'weightscale_table' ? (
                      <div>
                        <table
                          style={{
                            width: '100%',
                            minWidth: '700px',
                            borderCollapse: 'collapse',
                            border: '1px solid #ccc',
                            borderBottom: '1px solid #ccc',
                          }}
                        >
                          <thead>
                            <tr>
                              {tableHeaderName.map((eachTable, eachInd) => (
                                <th
                                  key={eachInd + 1 + ''}
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    backgroundColor: '#0057b8',
                                    color: "white",
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {eachTable?.label}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {images.map((eachImage, rowIndex) => (
                              <React.Fragment key={rowIndex}>
                                {/* Render the regular data row */}
                                <tr>
                                  {tableHeaderName.map((y, yIndex) => (
                                    <td
                                      key={yIndex}
                                      style={{ border: '1px solid #ccc', padding: '8px' }}
                                    >
                                      {typeof y.value === 'function'
                                        ? y.value(eachImage)
                                        : eachImage[y.value]}
                                    </td>
                                  ))}
                                </tr>
                                {/* Rendering the images in a separate row */}
                                <tr>
                                  <td
                                    colSpan={tableHeaderName.length} // Makes this row span across all columns
                                    style={{
                                      border: '1px solid #ccc',
                                      padding: '8px',
                                      textAlign: 'start',
                                      wordWrap: 'break-word',
                                    }}
                                  >
                                    {/* Check if there are images */}
                                    {eachImage?.images && eachImage?.images.length > 0 ? (
                                      // Grouping images by imageType (Keep it simple, no extra data transformation)
                                      Object.keys(eachImage?.images?.reduce((acc, image) => {
                                        if (!acc[image?.imageType]) {
                                          acc[image?.imageType] = [];
                                        }
                                        acc[image?.imageType].push(image);
                                        return acc;
                                      }, {})).map((imageType) => (
                                        <div key={imageType}>
                                          {/* <h5>{imageType}</h5> Display image type */}
                                          {/* <h5>{imageType?.replace(/_/g, ' ')}</h5> Replace underscores with spaces */}

                                          <h5>
                                            {
                                              imageType === 'PRE_STAGE' ? 'BEFORE CLEAN UP' :
                                                imageType === 'IN_PROGRESS_STAGE' ? 'DURING CLEAN UP' :
                                                  imageType === 'POST_STAGE' ? 'AFTER CLEAN UP' :
                                                    imageType?.replace(/_/g, ' ') // Fallback for other types
                                            }
                                          </h5>
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexWrap: 'wrap', // Allow images to wrap onto new lines if there are too many
                                              justifyContent: 'start',
                                              gap: '5px', // Space between images
                                            }}
                                          >
                                            {eachImage?.images
                                              .filter((image) => image?.imageType === imageType)
                                              .map((image, index) => (
                                                <img
                                                  key={index}
                                                  src={image?.url}
                                                  alt={`Image ${imageType} ${index + 1}`}
                                                  style={{ width: '320px', height: '320px', margin: '5px', borderRadius: "10px" }}
                                                />
                                              ))}
                                          </div>
                                        </div>
                                      ))
                                    ) : (
                                      <span>No images available</span>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}







                          </tbody>
                        </table>
                      </div>
                    ) : name === 'certification_table' ? (
                      <div>
                        {/* <div style={{ marginBottom: '20px' }}>
                          <h2 style={{ textAlign: 'start' }}>
                            Stage: Material picked up from clean up site
                          </h2>
                          <table
                            style={{
                              width: '100%',
                              minWidth: '700px',
                              borderCollapse: 'collapse',
                              border: '1px solid #ccc',
                            }}
                          >
                            <thead>
                              <tr>
                                {tableHeaderSecond.map((eachTable, eachInd) => (
                                  <th
                                    key={eachInd + 1 + ''}
                                    style={{
                                      border: '1px solid #ccc',
                                      padding: '8px',
                                      fontWeight: 'bold',
                                      backgroundColor: '#f3f3f3',
                                    }}
                                  >
                                    {eachTable?.label}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Weight
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Pound
                                </td>
                                {images.orderDetails.map((y, yInd) => (
                                  <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                    {y?.weight}
                                  </td>
                                ))}
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Bags
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Nos
                                </td>
                                {images.orderDetails.map((y, yInd) => (
                                  <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                    ({y?.quantity})
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div> */}

                        <div style={{ marginBottom: '20px' }}>
                          <h2 style={{ textAlign: 'start' }}>
                            Stage: Material Picked Up From Clean Up Site
                          </h2>
                          <table
                            style={{
                              width: '100%',
                              minWidth: '700px',
                              borderCollapse: 'collapse',
                              border: '1px solid #ccc',
                            }}
                          >
                            <thead>
                              <tr>
                                {tableHeaderSecond.map((eachTable, eachInd) => (
                                  <th
                                    key={eachInd + 1 + ''}
                                    style={{
                                      border: '1px solid #ccc',
                                      padding: '8px',
                                      fontWeight: 'bold',
                                      backgroundColor: '#0057b8',
                                      color: "white"
                                    }}
                                  >
                                    {eachTable?.label}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    // backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Weight
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    // backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Pound
                                </td>
                                {images.orderDetails.map((y, yInd) => (
                                  <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                    {y?.weight}
                                  </td>
                                ))}
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    // backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Bags
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    // backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Nos
                                </td>
                                {images.orderDetails.map((y, yInd) => (
                                  <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                    ({y?.quantity})
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* <div style={{ marginBottom: '20px' }}>
                          <h2 style={{ textAlign: 'start' }}>Stage: Weight after verfification</h2>
                          <table
                            style={{
                              width: '100%',
                              minWidth: '700px',
                              borderCollapse: 'collapse',
                              border: '1px solid #ccc',
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  KPI
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Unit
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Unsroted
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Plastic
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Non Plastic
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Weight
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Pound
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>44</td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>45</td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>56</td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Bags
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Nos
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>(45)</td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>(35)</td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>(32)</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}



                        <div style={{ marginBottom: '20px' }}>
                          <h2 style={{ textAlign: 'start' }}>Stage: Material Received at Sorting Facility</h2>
                          <table
                            style={{
                              width: '100%',
                              minWidth: '700px',
                              borderCollapse: 'collapse',
                              border: '1px solid #ccc',
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#0057b8',
                                    color: "white"
                                  }}
                                >
                                  KPI
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#0057b8',
                                    color: "white"
                                  }}
                                >
                                  Unit
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#0057b8',
                                    color: "white"
                                  }}
                                >
                                  Unsorted
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#0057b8',
                                    color: "white"
                                  }}
                                >
                                  Plastic
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#0057b8',
                                    color: "white"
                                  }}
                                >
                                  Non Plastic
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',

                                  }}
                                >
                                  Weight
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',

                                  }}
                                >
                                  Pound
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  {images?.materialRecDetails?.find((item) => item?.name === 'Unsorted')?.weight || 0}
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  {images?.materialRecDetails?.find((item) => item?.name === 'Plastic ')?.weight || 0}
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  {images?.materialRecDetails?.find((item) => item?.name === 'Non Plastic')?.weight || 0}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',

                                  }}
                                >
                                  Bags
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',

                                  }}
                                >
                                  Nos
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  ({images?.materialRecDetails?.find((item) => item?.name === 'Unsorted')?.quantity || 0})
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  ({images?.materialRecDetails?.find((item) => item?.name === 'Plastic ')?.quantity || 0})
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  ({images?.materialRecDetails?.find((item) => item?.name === 'Non Plastic')?.quantity || 0})
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>


                        {/* <div style={{ marginBottom: '5px' }}>
                          <h2 style={{ textAlign: 'start' }}>
                            Stage: Material picked up from clean up site
                          </h2>
                          <table
                            style={{
                              width: '100%',
                              minWidth: '700px',
                              borderCollapse: 'collapse',
                              border: '1px solid #ccc',
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  KPI
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Unit
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Unsroted
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Plastic
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Non Plastic
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Weight
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Pound
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>44</td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>45</td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>56</td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Bags
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Nos
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>(45)</td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>(35)</td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>(32)</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                        <div style={{ marginBottom: '20px' }}>
                          <h2 style={{ textAlign: 'start' }}>Stage: Material after verification</h2>
                          <table
                            style={{
                              width: '100%',
                              minWidth: '700px',
                              borderCollapse: 'collapse',
                              border: '1px solid #ccc',
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#0057b8',
                                    color: "white"
                                  }}
                                >
                                  KPI
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#0057b8',
                                    color: "white"
                                  }}
                                >
                                  Unit
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#0057b8',
                                    color: "white"
                                  }}
                                >
                                  Unsorted
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#0057b8',
                                    color: "white"
                                  }}
                                >
                                  Plastic
                                </th>
                                <th
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#0057b8',
                                    color: "white"
                                  }}
                                >
                                  Non Plastic
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    // backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Weight
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    // backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Pound
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  {images?.materialInspectionDetails?.find((item) => item.name === 'Unsorted')?.weight || 0}
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  {images?.materialInspectionDetails?.find((item) => item.name === 'Plastic ')?.weight || 0}
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  {images?.materialInspectionDetails?.find((item) => item.name === 'Non Plastic')?.weight || 0}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    // backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Bags
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    // backgroundColor: '#f3f3f3',
                                  }}
                                >
                                  Nos
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  ({images?.materialInspectionDetails?.find((item) => item.name === 'Unsorted')?.quantity || 0})
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  ({images?.materialInspectionDetails?.find((item) => item.name === 'Plastic ')?.quantity || 0})
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                  ( {images?.materialInspectionDetails?.find((item) => item.name === 'Non Plastic')?.quantity || 0})
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>




                      </div>
                    ) : (
                      (filterImage?.length ? filterImage : images)?.map((eachImage, eachind) => (
                        <img
                          height={200}
                          width={200}
                          src={eachImage.url || eachImage}
                          key={eachind + 1 + ''}
                          alt=''
                        />
                      ))
                    )}
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    onClick={close}
                    className='btn btn-light'
                    data-bs-dismiss='modal'
                  >
                    Close
                  </button>
                </div>
                {/* end::Modal body */}
              </div>
              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      }
    </>
  )
}

export { ViewImageModal }